import React from 'react';
import { Button } from 'semantic-ui-react';

import './Launch.css';
import clientConfig from './client.json';
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = 'description';
  subheading?: string = 'subheading';
  title?: string = 'title';
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  return (
    <div id="launchContainer">

      <div>
        {<p>{client.description}</p>}
        {<h1>{client.subheading}</h1>}
      </div>

      <div>
        <Button size="massive" color="black" circular icon="play" z-index="0" onClick={() => props.Launch()}></Button>
        <p className="experience">Begin your Experience</p>

      </div>
    </div>
  );
};
